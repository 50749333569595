<template>
  <div class="">
    <van-popup
      :value="show"
      position="bottom"
      style='overflow:visible'
      @click-overlay="cancel"
      @open="open"
    >
      <!-- 操作 -->
      <div class="dis_flex ali_it_cen bg_col_f9f9f9">
        <div class="font_14 p_8_16 active_bg_col_ddd" @click="cancel">取消</div>

        <div class="flex_1"></div>

        <div class="font_14 p_8_16 active_bg_col_ddd" @click="confirm">
          确认
        </div>

        <!--  -->
      </div>

      <!-- 列表 -->
      <div class="max_h_80_vw over_y_auto">
        <van-radio-group v-model="value">
          <!-- 项 -->
          <div v-for="(item, index) in columns" :key="index">
            <van-radio class="p_12_16 active_bg_col_ddd" :name="item.value">{{
              item.label
            }}</van-radio>

            <!-- 灰线 -->
            <div class="h_1 bg_col_F5F7F9"></div>

            <!--  -->
          </div>
        </van-radio-group>

        <!--  -->
      </div>

      <!--  -->
    </van-popup>

    <!--  -->
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,

    columns: {
      type: Array,
      eg: [
        {
          label: 'xxx',
          value: 'xxx',
        },
      ],
      defautl: () => [],
    },

    /**
     * 选中的
     */
    selectedValue: {
      type: [Number, String],
      defautl: () => null,
    },

    //
  },
  data() {
    return {
      value: null,

      //
    };
  },
  created() {},
  methods: {
    // 确定
    confirm() {
      this.$emit(
        'handleConfirm',
        this.$props.columns.find((it) => it.value == this.value)
      );
      this.cancel();
    },

    // 取消
    cancel() {
      this.$emit('update:show', false);
    },

    // 打开
    open() {
      this.value = this.$props.selectedValue;

      //
    },

    //
  },
};
</script>

<style lang="scss" scoped>
</style>
